<template>
  <TextMediaBlock
    :id="item?.fields.id ?? `section-${item.sys.id}`"
    :media="media"
    :text-side="item.fields.textPosition ?? 'left'"
    :text-lead="false"
    :text-centered="true"
    :offset-media-left="item.fields.offsetMediaLeft ?? false"
    :offset-media-right="item.fields.offsetMediaRight ?? false"
    :offset-text-left="item.fields.offsetTextLeft ?? false"
    :offset-text-right="item.fields.offsetTextRight ?? false"
    v-bind="
      ContentfulLivePreview.getProps({
        entryId: item.sys.id,
        fieldId: 'teasers'
      })
    "
  >
    <RichTextRenderer v-if="item.fields.text" :rich-text="item.fields.text" />
  </TextMediaBlock>
</template>

<script setup lang="ts">
import { TextMediaBlock } from '@hypercodestudio/basler-components';
import RichTextRenderer from '../lib/RichTextRenderer.vue';
import type { ITextMedia } from '~/lib/ContentfulService';
import { useMedia } from '~/composables/useMedia';
import { ContentfulLivePreview } from '@contentful/live-preview';

interface Props {
  item: ITextMedia;
}

const props = defineProps<Props>();

const media = await useMedia(props.item.fields.media);
</script>
